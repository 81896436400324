import React from "react";
import { Helmet } from "react-helmet";
import { MapContainer, TileLayer } from "react-leaflet";

import MarkerItem from "components/MarkerItem";
import PageTitle from "components/PageTitle";
import { basics, map } from "config";

import VenueCard from "components/VenueCard";
import { venues } from "config";


const Transportation = () => {
  const accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

  return (
    <article className="space-y-8 w-full">
      <PageTitle>Doprava</PageTitle>
      <Helmet>
        <title>Doprava | {basics.appTitle}</title>
        <meta property="og:title" content="Mapa" />
      </Helmet>

      <div className="content-block">
        <h2>Kde zasedání CF a doprovodný program probíhá?</h2>
      </div>

      <div className="grid grid-cols-4 gap-4">
        <MapContainer
          center={[map.center.lat, map.center.lon]}
          zoom={map.center.zoom}
          scrollWheelZoom={true}
          style={{ height: "600px", width: "100%" }}
          className="col-span-4"
        >
          <TileLayer
            url={`https://api.mapbox.com/styles/v1/xaralis/ck4oblwty0fgk1fjzxmqow2r5/tiles/256/{z}/{x}/{y}@2x?access_token=${accessToken}`}
            attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
          />
          {map.markers.map((marker) => (
            <MarkerItem
              key={marker.title}
              type={marker.type}
              coords={marker.coords}
              desc={
                <>
                  <strong>{marker.title}</strong>
                  <div>{marker.desc}</div>
                </>
              }
            />
          ))}
        </MapContainer>
      </div>

      <div className="grid md:grid-cols-3 gap-4 md:gap-4 content-block">
        {venues.map((venueItem) => (
          <VenueCard
            street={venueItem.street}
            city={venueItem.city}
            gps={venueItem.gps}
            href={venueItem.href}
            title_short={venueItem.title_short}
            key={venueItem.title_short}
          />
        ))}
      </div>

      <div className="content-block">
        <h2>Jak se dostanu do Brna?</h2>

        <p>Pas, ani vízum netřeba. Internet tu taky známe. Tak jsme si odbyli nějaké ty klasické žertíky.</p>

        <p>Do Brna jezdí vlaky i autobusy ze všech koutů Česka, stejně tak se sem dá pohodlně dostat i autem.</p>
        <h2>A jak v Brnečko?</h2>

        <h3>MHD</h3>

        <p>Nejbližší zastávka u Sona je Tábor, pro linky: tramvaje 10 a 3, autobus 68</p>
        <p>Café Práh/Galerie Vaňkovka je nachází mezi ÚAN Zvonařka a Brno Hlavní nádraží.</p>
        <p>Pro cestování v MHD doporučujeme <a href="https://www.pipniajed.cz/">službu Pípni a jeď (Bezkontaktní platba za jízdenku)</a> nebo je možné využít <a href="https://www.dpmb.cz/jizdenky-v-mobilu">sms jízdenku</a>.</p>

        <p>
        Doprava z hlavního nádraží je tramvají číslo 10 (boční nástupiště směrem ke Grandhotelu) přímo na zastávku Tábor,
        případně tramvají číslo 4, 9 a 12 na zastávku Česká a od tama tram 3 nebo 10 na zastávku Tábor.
        </p>

        <p>ŠPiCe (Pirátské centrum) se nachází v blízkosti zastávky Pionýrská, ve které zastavují tramvaje číslo 1 a 6 a trolejbusy číslo 25 a 26</p>

        <p>Mezi ŠPiCí a Café Práh jezdí tramvaj číslo 1 (Hlavní nádraží - Pionýrská, 3 mezizastávky)</p>
        <h3>Parkování</h3>

        <h4>Sobota</h4>
        <p>O víkendu je v Brně parkování bezplatné (modré zóny). V okolí Sona najdeš celou řadu parkovišť.</p>
        <ul>
        <li><a href="https://en.mapy.cz/s/lumarefura">Parkoviště Veveří</a> nad Björnsonovým sadem s kapacitou 140 míst</li>
        <li><a href="https://en.mapy.cz/s/kokodukese">Parkoviště Šumavská</a> i s podzemním parkovištěm u Lidlu</li>
        <li><a href="https://en.mapy.cz/s/cupahereru">Parkoviště Gymnázium Matyáše Lercha</a></li>
        </ul>

        <h4>Neděle</h4>
        <p>
        Café Práh se nachází u <a href="https://en.mapy.cz/s/nofegelofu">Galerie Vaňkovka</a>, vchod z ulice Ve Vaňkovce. Parkování je dostatek právě v Galerie Vaňkovka - v neděli je zdarma.
        Přímo v Galerii Vaňkovka je několik restaurací a food corner
        </p>


      </div>
    </article>
  );
};

export default Transportation;
