import { Helmet } from "react-helmet";

import PageTitle from "components/PageTitle";
import { basics } from "config";

import Button from "components/Button";
import VenueCard from "components/VenueCard";
import { venues } from "config";

import prvni_patro from "../mk_e.jpg";
import druhe_patro from "../mk_i.jpg";
import planek from "../mk_p.png";

const Meeting = () => {
  const venueItem = venues[1];
  return (
    <article className="w-full">
      <PageTitle>Zasedání CF</PageTitle>

      <Helmet>
        <title>Zasedání CF | {basics.appTitle}</title>
        <meta property="og:title" content="Workshopy" />
      </Helmet>

      <div className="content-block">
        <h2>Zasedání Celostátního fóra v Praze</h2>
        <p>

Mimořádné zasedání <a href="https://forum.pirati.cz/viewtopic.php?p=886987#p886987">svolal</a> 23. září 2024
předseda strany Ivan Bartoš po <a href="https://forum.pirati.cz/viewtopic.php?p=886661#p886661">rezignaci</a> celého
republikového předsednictva z 22. září 2024.
Zasedání je svoláno na sobotu 9. listopadu 2024 od 9:00 do 18:00 do Prahy. Členům bude umožněna vzdálená účast.
Prvním bodem zasedání je <a href="https://forum.pirati.cz/viewtopic.php?t=67703">volba nového předsednictva</a>.
O dalších bodech programu se nyní <a href="https://forum.pirati.cz/viewtopic.php?t=67732">jedná</a>.
Hlavním <a href="/organizatori">organizátorem</a> je Jiří Belšán.

        </p>
      </div>
{/*

      <Button
        className="text-lg btn--to-blue-300 mb-2"
        bodyClassName="whitespace-no-wrap"
        color="black"
        href="program#sobota 9. 11. 2024"
        icon="ico--chevron-right"
      >
        Pořad zasedání
      </Button>
      &nbsp;
*/}
      <Button
        className="text-lg btn--to-blue-300 mb-2"
        bodyClassName="whitespace-no-wrap"
        color="black"
        href="https://a.pirati.cz/cf/2024-11/"
        icon="ico--chevron-right"
        target="_blank"
      >
        Podklady ke stažení
      </Button>

      <div className="content-block">
        <p>
          <img
            src={prvni_patro}
            alt="Exterier"
            className="block w-128 mt-4 mb-4"
          />

        </p>
        <p>
          <img
            src={druhe_patro}
            alt="Sal"
            className="block w-128 mt-4 mb-4"
          />

        </p>
        <p>
          <img
            src={planek}
            alt="Planek"
            className="block w-128 mt-4 mb-4"
          />

        </p>
      </div>


      <div className="content-block p-2">
      </div>

      <div className="grid md:grid-cols-2 gap-4 md:gap-4 content-block">
        <VenueCard
          street={venueItem.street}
          city={venueItem.city}
          gps={venueItem.gps}
          href={venueItem.href}
          title_short={venueItem.title_short}
        />
      </div>

    </article>
  );
};

export default Meeting;
