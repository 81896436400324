import { Helmet } from "react-helmet";

import PageTitle from "components/PageTitle";
import { basics } from "config";

const Disabled = () => {
  return (
    <article className="w-full">
      <PageTitle>CF bez bariér</PageTitle>

      <Helmet>
        <title>CF bez bariér | {basics.appTitle}</title>
        <meta property="og:title" content="CF bez bariér" />
      </Helmet>

      <div className="content-block">
        <p>
          Jak to bude s bariérami?
          Piráti se maximálně snaží odbourávat bariéry a nejinak to bude i na letošním CF!
        </p>
        <h3 className="head-alt-md">WARM - UP PARTY v klubu Spot</h3>
        <p>
          Spot disponuje bočním vchodem, stačí na vchodu požádat o&nbsp;jeho použití a&nbsp;přes pár zbývajících schůdků Vám bude pomoženo. Dále už na Vás žádné nástrahy nečekají, ale pro jistotu si vemte parťáka s&nbsp;sebou.
        </p>

        <h3 className="head-alt-md">Dům Kultury Liberec</h3>
        <p>
          Největší bariérou v&nbsp;Domě kultury Liberec je to, že se celé dění odehrává v&nbsp;1.&nbsp;patře.
          To ale společně překonáme! K&nbsp;dispozici Vám bude přítel na telefonu, který obsluhuje restaurační výtah a&nbsp;doprovodí Vás při vstupech i&nbsp;výstupech z&nbsp;objektu.
          Pokud máte v&nbsp;plánu naši službu využít, kontaktuje prosím organizátorku Hanku Černošovou, +420 770 113 645, <a href="mailto:hana.cernosova@pirati.cz">hana.cernosova@pirati.cz</a>.
        </p>

        <h3 className="head-alt-md">WORKSHOPY v kině Varšava</h3>
        <p>
          V&nbsp;tomto místě handicapované nepotěšíme. Bariérový vstup o&nbsp;pár schodech lze za pomoci několika silných paží zvládnout, pomoc na vyžádání na místě zajistíme. Do velkého sálu se pak už vozíčkář dostane.
          Ovšem zasedací místnost a&nbsp;hlavně WC se bohužel nachází v&nbsp;prvním patře bez výtahu.
          Zvažte proto svou účast. Pro odvážné však uděláme maximum!
        </p>

      </div>
    </article>
  );
};

export default Disabled;
