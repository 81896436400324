import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import PageTitle from "components/PageTitle";
import { api, appurl, basics } from "config";

import { useParams } from "react-router-dom";

const Payment = () => {
  const { id } = useParams();
  const secure_id = id.slice(0, 5);
  const [payment_info, setPayment] = useState(false);
  const [payment_status, setPaymentStatus] = useState(false);


  useEffect(() => {
    //console.log('Api: '+`${api.baseUrl}/orders/${id}/payment`);
    //setPayment(false);
    //setPaymentStatus(false);

    const doFetch = async () => {
      const [resp] = await Promise.all([
        fetch(`${api.baseUrl}/orders/${id}/payment`).then((r) => r.json()),
      ]);

      //console.log('Odpověď API:'+JSON.stringify(resp));

      if (resp["amount"] !== undefined) setPayment(resp);
      if (resp["payed"] !== undefined) setPaymentStatus(true); else setPaymentStatus(false);

    };
    doFetch();

  }, []);


  return (
    <article className="w-full">
      <PageTitle>Platba</PageTitle>

      <Helmet>
        <title>Platba | {basics.appTitle}</title>
        <meta property="og:title" content="Payment" />
      </Helmet>

      <div className="content-block">
        <h2>
          Informace o platbě s ID objednávky {secure_id}
        </h2>

        {!payment_info && (
          <div>
            <p>
              Objednávka s ID {secure_id} neexistuje.
            </p>
          </div>
        )}

        {payment_info && payment_info.amount != "0.00" && (
          <div className="grid md:grid-cols-2 gap-4 md:gap-8 content-block">
            <div>
              <p>
                <strong>Informace k platbě</strong><br />
                Jméno a příjmení: {payment_info.payer}<br />
                {payment_status && (
                  <span className="alert alert--green-400">UHRAZENO</span>
                )}
                {!payment_status && (
                  <span className="alert alert--red-600">ČEKÁ NA ÚHRADU</span>
                )}

                <br />
                <strong>Pokyny k platbě:</strong><br />
                Číslo účtu: {payment_info.account}<br />
                Částka: {payment_info.amount} Kč<br />
                Variabilní symbol: {payment_info.vs}<br />
                Konstantní symbol: {payment_info.ks}<br />
                Specifický symbol: {payment_info.ss}<br /><br />
                {!payment_status && (
                  <span>Případně použijte QR kód k platbě z Vašeho mobilního telefonu.</span>
                )}
              </p>
            </div>
            <div>
              {!payment_status && (
                <p>
                  <img src={appurl.baseUrl + payment_info.qr} alt="platba - qr kód" />
                </p>
              )}
            </div>
          </div>
        )}

        {payment_info && payment_info.amount === "0.00" && (
          <div>
            <p>
              Objednávka s ID {secure_id} nezahrnuje žádnou platbu.
            </p>
          </div>
        )}

      </div>
    </article>
  );
};

export default Payment;
