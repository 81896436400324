import React, { Suspense, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ReactHintFactory from "react-hint";
import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { ReactKeycloakProvider as KeycloakProvider } from "@react-keycloak/web";
import * as Sentry from "@sentry/react";
import { browserTracingIntegration } from "@sentry/browser"
import { extraErrorDataIntegration } from "@sentry/integrations";
import classNames from "classnames";

import MobileNav from "components/MobileNav";
import { basics } from "config";
import Transportation from "pages/Transportation";

import VNav from "./components/VNav";
import Accommodation from "./pages/Accommodation";
import Catering from "./pages/Catering";
import Home from "./pages/Home";
import LeaderElection from "./pages/LeaderElection";
import Map from "./pages/Map";
import Party from "./pages/Party";
import Program from "./pages/Program";
import Registration from "./pages/Registration";
import Registration2 from "./pages/Registration2";
import Team from "./pages/Team";
import Volunteers from "./pages/Volunteers";
import Workshops from "./pages/Workshops";
import Meeting from "./pages/Meeting";
import Disabled from "./pages/Disabled";
import KidsnFun from "./pages/KidsnFun";
import Payment from "./pages/Payment";
import cfLogo from "./cf2023_kormidlo_lod.svg";
import keycloak from "./keycloak";
import partyLogo from "./logo-full-black.svg";

import "./styles.css";

/**
 * If configured, set up Sentry client that reports uncaught errors down to
 * https://sentry.io.
 */
if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    tracesSampleRate: 0.1,
    integrations: [extraErrorDataIntegration(), new browserTracingIntegration()],
  });
}

const ReactHint = ReactHintFactory(React);

function AppHeaderVenueInfo() {
  const location = useLocation();
  const isRoot = location.pathname === "/";
  const [mobileNavOpen, setMobnileNavOpen] = useState(false);

  // Close menu on navigation
  useEffect(() => {
    setMobnileNavOpen(false);
  }, [location]);

  return (
    <>
      <MobileNav
        isOpen={mobileNavOpen}
        onClose={() => setMobnileNavOpen(false)}
      />
      <div className="flex justify-center space-x-4 md:space-x-0 md:block md:text-right md:ml-auto head-alt-xs md:head-alt-base">
        <h2>{process.env.REACT_APP_CF_EVENT_DATE}</h2>
        <h2>{process.env.REACT_APP_CF_EVENT_CITY}</h2>
        <h2>{process.env.REACT_APP_CF_EVENT_VENUE}</h2>
      </div>
      {/* Mobile nav trigger */}
      <div
        className={classNames({
          "mt-4 block md:hidden": !isRoot,
          hidden: isRoot,
        })}
      >
        <button
          className="head-alt-base"
          onClick={() => setMobnileNavOpen(true)}
        >
          Menu
        </button>
      </div>
    </>
  );
}

function PageBody() {
  const location = useLocation();
  const isRoot = location.pathname === "/";

  return (
    <div
      className={classNames({
        "flex flex-col md:flex-row md:space-x-8": !isRoot,
      })}
    >
      <Routes>
        <Route path="/registrace-vip" element={<Registration />} />
        <Route path="/registrace" element={<Registration2 />} />
        <Route path="/stravovani" element={<Catering />} />
        <Route path="/organizatori" element={<Team />} />
        <Route path="/ubytovani" element={<Accommodation />} />
        <Route path="/mapa" element={<Map />} />
        <Route path="/program" element={<Program />} />
        <Route path="/doprava" element={<Transportation />} />
        <Route path="/pro-dobrovolniky" element={<Volunteers />} />
        <Route path="/party" element={<Party />} />
        <Route path="/volba-predsednictva" element={<LeaderElection />} />
        <Route path="/workshopy" element={<Workshops />} />
        <Route path="/zasedani" element={<Meeting />} />
        <Route path="/hendikepovani" element={<Disabled />} />
        <Route path="/deti-a-zabava" element={<KidsnFun />} />
        <Route path="/platba/:id" element={<Payment />} />
        <Route path="/" element={<Home />} />
      </Routes>

      {!isRoot && (
        <VNav className="hidden md:block mt-8 md:mt-0 md:w-1/4 p-4 md:p-8" />
      )}
    </div>
  );
}

function BaseApp() {
  const year = new Date().getFullYear();

  return (
    <Router>
      <Helmet>
        <title>{basics.appTitle} | Pirátská strana</title>
        <meta name="description" content={basics.appDesc} />
      </Helmet>

      <div className="container container--default my-4 md:my-16 space-y-8 md:space-y-16">
        <header className="md:flex items-center text-center md:text-left">
          <h1 className="head-alt-lg md:head-alt-xl cf-app-title">
            <Link to="/" className="no-underline hover:no-underline">
              {basics.appTitle}
            </Link>
          </h1>
          <AppHeaderVenueInfo />
        </header>

        <PageBody />

        <footer className="border-t border-grey-125 pt-4 flex flex-col md:flex-row items-center md:justify-between space-y-4  text-center md:text-left">
          <img src={partyLogo} className="w-32" alt="Pirátská strana" />
          <p>Piráti, {year}. Všechna práva vyhlazena.</p>
        </footer>
      </div>

      <ReactHint
        autoPosition
        events
        attribute="data-tip"
        className="tooltip"
        onRenderContent={(target, content) => (
          <span className="block max-w-xs leading-tight">{content}</span>
        )}
      />
    </Router>
  );
}

const LoadingComponent = (
  <div className="h-screen w-screen flex justify-center items-center">
    <div className="text-center">
      <div className="flex flex-col md:flex-row items-center space-x-4 text-center mb-2">
        <img
          src={cfLogo}
          alt="CF 2023"
          className="hidden lg:block w-32 mr-16"
        />
      </div>
    </div>
  </div>
);

const AuthenticatedApp = () => {
  const keycloakInitConfig = {
    onLoad: "check-sso",
    // Necessary to prevent Keycloak cookie issues:
    // @see: https://stackoverflow.com/a/63588334/303184
    checkLoginIframe: false,
  };

  const onKeycloakEvent = async (event) => {
    if (["onAuthRefreshSuccess", "onAuthSuccess"].includes(event)) {
      Sentry.setUser(keycloak.tokenParsed);
    }
  };

  return (
    <>
      <KeycloakProvider
        authClient={keycloak}
        initOptions={keycloakInitConfig}
        LoadingComponent={LoadingComponent}
        onEvent={onKeycloakEvent}
      >
        <Suspense fallback={LoadingComponent}>
          <BaseApp />
        </Suspense>
      </KeycloakProvider>
    </>
  );
};

const ErrorBoundaryFallback = ({ error }) => {
  return (
    <div className="h-screen w-screen flex justify-center items-center">
      <div className="text-center">
        <h1 className="head-alt-xl text-red-600 mb-4">
          V aplikaci došlo k chybě :(
        </h1>
        <p className="text-lg leading-normal">
          Naši vývojáři o tom již byli informování a opraví to co nejdříve.
          <br />
          Omlouváme se za tuto nepříjemnost.
        </p>
        <a href="/" className="btn mt-8">
          <div className="btn__body">Načíst znovu</div>
        </a>
      </div>
    </div>
  );
};

const App = Sentry.withProfiler(() => {
  return (
    <Sentry.ErrorBoundary fallback={ErrorBoundaryFallback} showDialog>
      <AuthenticatedApp />
    </Sentry.ErrorBoundary>
  );
});
export default App;
