
import { Helmet } from "react-helmet";

import PageTitle from "components/PageTitle";
import { basics } from "config";

const Registration2 = () => {
  return (
    <article className="w-full">
      <PageTitle>Registrace byla ukončena</PageTitle>

      <Helmet>
        <title>Registrace | {basics.appTitle}</title>
        <meta property="og:title" content="Registrace" />
      </Helmet>

      <div className="content-block">

<p>
Registrace na zasedání Celostátního fóra 2024 byla z kapacitních důvodů ukončena. To však neznamená, že přijdete o možnost účasti – zasedání můžete sledovat a zapojit se online na adrese <a href="https://cf2024.online/">cf2024.online</a>
</p>

      </div>
    </article>
  );
};

export default Registration2;
