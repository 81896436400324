import Button from "components/Button";
import { Helmet } from "react-helmet";

import PageTitle from "components/PageTitle";
import { basics } from "config";


/* Podklady ze zulipu
Kulaťák - https://kulatak.cz/
česká, 900 m od místa konání

Na Urale - https://www.naurale.cz/
česká, 1 300 m od místa konání

U cedru - http://www.ucedru.cz/
libanonská, 1 000 m od místa konání

Vejcedejvice - https://vejcedejvice.cz/
bistro, 1 000 m od místa konání

Grosseto - https://www.grosseto.cz/
pizzerie, 850 m od místa konání

U pětníka - https://www.upetnika.cz/
česká, 950 m od místa konání

Fresh Point - https://fresh-point.cz/
pizza take away, 1 000 m od místa konání

U veverky - https://www.uveverky.com/
česká, 1 300 m od místa konání

Automat Matuška - https://www.automatmatuska.cz/
hipster jídelna, 1 400 m od místa konání

Místo - https://mistoprovas.cz/
kavárna, kde vaří, 1 500 m od místa konání
*/

const Catering = () => {
  return (
    <article className="w-full">
      <PageTitle>Stravování</PageTitle>

      <Helmet>
        <title>Stravování | {basics.appTitle}</title>
        <meta property="og:title" content="Stravování" />
      </Helmet>

      <div className="content-block">
        <h2>Kam na jídlo</h2>

<ul>
<li>
Kulaťák - https://kulatak.cz/
<br/>česká, 900 m od místa konání
</li>

<li>
Na Urale - https://www.naurale.cz/
<br/>česká, 1 300 m od místa konání
</li>

<li>
U cedru - http://www.ucedru.cz/
<br/>libanonská, 1 000 m od místa konání
</li>

<li>
Vejcedejvice - https://vejcedejvice.cz/
<br/>bistro, 1 000 m od místa konání
</li>

<li>
Grosseto - https://www.grosseto.cz/
<br/>pizzerie, 850 m od místa konání
</li>

<li>
U pětníka - https://www.upetnika.cz/
<br/>česká, 950 m od místa konání
</li>

<li>
Fresh Point - https://fresh-point.cz/
<br/>pizza take away, 1 000 m od místa konání
</li>

<li>
U veverky - https://www.uveverky.com/
<br/>česká, 1 300 m od místa konání
</li>

<li>
Automat Matuška - https://www.automatmatuska.cz/
<br/>hipster jídelna, 1 400 m od místa konání
</li>

<li>
Místo - https://mistoprovas.cz/
<br/>kavárna, kde vaří, 1 500 m od místa konání
</li>
</ul>

      </div>


      {/*
      <Button
              className="md:text-xl btn--to-blue-300 mt-8 mb-8"
              bodyClassName="whitespace-no-wrap"
              color="black"
              icon="ico--chevron-right"
              href={mapa_pdf}
              target="_blank"
            >
              Mapa restaurací v okolí konání workshopů
      </Button>
*/}

    </article>
  );
};

export default Catering;
