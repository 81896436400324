import React from "react";
import { Helmet } from "react-helmet";
import {
  LayerGroup,
  LayersControl,
  MapContainer,
  TileLayer,
} from "react-leaflet";

import MarkerItem from "components/MarkerItem";
import PageTitle from "components/PageTitle";
import { accommodation, basics, map } from "config";

import VenueCard from "components/VenueCard";
import { venues } from "config";

const Map = () => {
  const markerGroups = {
    basics: {
      name: "Základ",
      items: [],
    },
    accommodation: {
      name: "Ubytování",
      items: [],
    },
    transportation: {
      name: "Doprava",
      items: [],
    },
  };

  map.markers.forEach((marker) => {
    markerGroups[marker.group].items.push({
      type: marker.type,
      coords: marker.coords,
      title: marker.title,
      desc: (
        <>
          <strong>{marker.title}</strong>
          <div>{marker.desc}</div>
        </>
      ),
    });
  });

  accommodation.forEach((place) => {
    markerGroups.accommodation.items.push({
      type: "accommodation",
      coords: place.coords,
      title: place.title,
      desc: (
        <>
          <strong>{place.title}</strong>
          <div>{place.priceClass}</div>
          <div>{place.desc}</div>
        </>
      ),
    });
  });

  const accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

  return (
    <article className="space-y-8 w-full">
      <PageTitle>Mapa</PageTitle>
      <Helmet>
        <title>Mapa | {basics.appTitle}</title>
        <meta property="og:title" content="Mapa" />
      </Helmet>

      <div className="container-padding--zero md:container-padding--auto">
        <MapContainer
          center={[map.center.lat, map.center.lon]}
          zoom={map.center.zoom}
          scrollWheelZoom={true}
          style={{ height: "600px", width: "100%" }}
        >
          <LayersControl position="topright" collapsed={false}>
            <TileLayer
              url={`https://api.mapbox.com/styles/v1/xaralis/ck4oblwty0fgk1fjzxmqow2r5/tiles/256/{z}/{x}/{y}@2x?access_token=${accessToken}`}
              attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
            />
            {Object.keys(markerGroups).map((groupKey) => (
              <LayersControl.Overlay
                checked
                name={`<span class="head-allcaps-3xs">${markerGroups[groupKey].name}</span>`}
                key={markerGroups[groupKey].name}
              >
                <LayerGroup>
                  {markerGroups[groupKey].items.map((marker) => (
                    <MarkerItem
                      key={marker.title}
                      type={marker.type}
                      coords={marker.coords}
                      title={marker.title}
                      desc={marker.desc}
                    />
                  ))}
                </LayerGroup>
              </LayersControl.Overlay>
            ))}
          </LayersControl>
        </MapContainer>
      </div>

      <div className="grid md:grid-cols-3 gap-4 md:gap-4 content-block">
        {venues.map((venueItem) => (
          <VenueCard
            street={venueItem.street}
            city={venueItem.city}
            gps={venueItem.gps}
            href={venueItem.href}
            title_short={venueItem.title_short}
            key={venueItem.title_short}
          />
        ))}
      </div>
    </article>
  );
};

export default Map;
