import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import classNames from "classnames";

import Button from "components/Button";
import PageTitle from "components/PageTitle";
import RegistrationForm from "components/RegistrationForm";
import { api, appurl, basics } from "config";

const resolveAudience = (keycloak) => {
  if (!keycloak.authenticated) {
    return undefined;
  }

  if (keycloak.tokenParsed.groups) {
    const hasMemberGroup =
      keycloak.tokenParsed.groups.filter((g) => g.match(".*:f")).length > 0;
    if (hasMemberGroup) {
      return "member";
    }
    const hasRegPGroup =
      keycloak.tokenParsed.groups.filter((g) => g.match(".*:regp")).length > 0;
    if (hasRegPGroup) {
      return "regp";
    }
  }
  //return undefined;
  return "notmember";
};

const Registration = () => {
  const { keycloak } = useKeycloak();
  const [questions, setQuestions] = useState();
  const [products, setProducts] = useState();
  const [audience, setAudience] = useState(resolveAudience(keycloak));
  const [formData, setFormData] = useState();
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState();
  const [submitDuplicity, setSubmitDuplicity] = useState(false);
  const [registrationFormRenderKey, setRegistrationFormRenderKey] = useState(0);
  const [response, setResponse] = useState("");
  //const [responseTotal, setResponseTotal] = useState(0);

  useEffect(() => {
    const doFetch = async () => {
      const [allQuestions] = await Promise.all([
        fetch(`${api.baseUrl}/questions`).then((r) => r.json()),
      ]);

      setQuestions(allQuestions);

      const [allProducts] = await Promise.all([
        fetch(`${api.baseUrl}/products`).then((r) => r.json()),
      ]);

      setProducts(allProducts);
    };

    doFetch();
  }, []);

  const submit = async () => {
    setSubmitting(true);
    setSubmitError(null);
    setSubmitDuplicity(false);

    try {
      const payload = formData;

      payload.products.push(
        {
          member: {
            id: 1,
          },
          regp: {
            id: 4,
          },
          guest: {
            id: 2,
          },
          journalist: {
            id: 3,
          },
        }[audience]
      );

      if (keycloak.token) {
        payload.token = keycloak.token;
      }

      const resp = await fetch(`${api.baseUrl}/orders`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!resp.ok) {
        const error = new Error(resp.statusText);
        error.response = resp;
        throw error;
      }
      console.log(JSON.stringify(resp));
      const odpoved = await resp.json();
      console.log(JSON.stringify(odpoved));
      setResponse(odpoved);
      setSubmitError(null);
      setSubmitted(true);
    } catch (err) {
      console.error(err);

      const resp = await err.response.json();

      if (resp.errors.filter((err) => err.message === "Duplicity").length > 0) {
        setSubmitDuplicity(true);
      } else {
        setSubmitError(err);
      }

      setSubmitted(false);
    } finally {
      setSubmitting(false);
    }
  };

  const resetAudience = () => {
    setAudience(undefined);
    setFormData(undefined);
    setRegistrationFormRenderKey(registrationFormRenderKey + 1);
  };

  return (
    <article className="space-y-8 w-full">
      <PageTitle>Registrace</PageTitle>

      <Helmet>
        <title>Registrace | {basics.appTitle}</title>
        <meta property="og:title" content="Registrace" />
      </Helmet>

      <p className="leading-normal">
        Registrace jsou oficiálně spuštěny.<br/>
        Pokud se chcete zúčastnit {basics.appTitle}, vyplňte prosím registrační
        formulář.
      </p>

      {!submitted && (
        <div className="space-y-8">
          <h2 className="head-heavy-sm">1. Jaký máte vztah k Pirátům?</h2>

          {audience === "notmember" && (
            <p>
              Registrovaní příznivci jsou na zasedání CF považováni za hosty.
            </p>
          )}

          {!audience && (
            <>
              <div className="flex flex-col space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">

                <Button
                  className="btn--black max-w-full sm:max-w-xs lg:max-w-full"
                  bodyClassName="whitespace-no-wrap"
                  color="black"
                  routerTo="/registrace-vip"
                  onClick={() => keycloak.login()}
                >
                  Členstvo nebo RegP
                  <span className="hidden lg:inline">
                    &nbsp;(vyžaduje přihlášení)
                  </span>
                </Button>
{/*
                <Button
                  className="btn--violet-400 max-w-full sm:max-w-xs lg:max-w-full"
                  bodyClassName="whitespace-no-wrap"
                  color="black"
                  routerTo="/registrace"
                  onClick={() => setAudience("journalist")}
                >
                  Média
                </Button>
*/}

                <Button
                  className="btn--blue-300 max-w-full sm:max-w-xs lg:max-w-full"
                  bodyClassName="whitespace-no-wrap"
                  color="black"
                  routerTo="/registrace-vip"
                  onClick={() => setAudience("guest")}
                >
                  Ostatní (hosté)
                </Button>
              </div>

{/*
      <div className="content-block">
        <h4>Důležité upozornění:</h4>
        <p>Registrace pro osoby, které nejsou členy nebo registrováné přiznivce Pirátů, už byla ukončena z kapacitních důvodu. Děkujeme za pochopení</p>
      </div>

              div className="content-block">
                <h4>Důležité upozornění:</h4>
                <p>Kdokoliv se chce registrovat pro účast na CF, ale není členem strany nebo novinářem, má na Celostátním fóru status <strong>hosta</strong> a musí se pro účast zaregistrovat jako <strong>host</strong>. I&nbsp;registrovaný příznivec (regP) se musí registrovat jako <strong>host</strong>.</p>
              </div>
*/}


            </>
          )}

          {audience && (
            <div
              className={classNames(
                "alert alert--grey-125 leading-normal space-x-4",
                { "items-center": audience !== "member" }
              )}
            >
              <span className="ico--checkbox-checked text-3xl" />
              <div>
                {(audience === "member" || audience === "regp" ) && (
                  <>
                    <p>
                      Registrujete se jako{" "}
                      <strong>{keycloak.tokenParsed.name}</strong>.
                    </p>
                    <p>
                      Použili jsme účet svázaný s vaší pirátskou identitou.
                      Pokud se snad chcete přihlásit pod jinou identitou, je
                      nutné se nejprve{" "}
                      <button className="underline" onClick={keycloak.logout}>
                        odhlásit
                      </button>
                      .
                    </p>
                  </>
                )}
                {audience === "guest" && (
                  <p>
                    Registrujete se jako <strong>host</strong>. Něco nesedí? Tak
                    to{" "}
                    <button className="underline" onClick={resetAudience}>
                      zkuste znovu
                    </button>
                    .
                  </p>
                )}
                {audience === "journalist" && (
                  <p>
                    Registrujete se jako <strong>novinář</strong>. Něco nesedí?
                    Tak to{" "}
                    <button className="underline" onClick={resetAudience}>
                      zkuste znovu
                    </button>
                    .
                  </p>
                )}
                {audience === "notmember" && (
                  <div>
                    <p>
                    Jste přihlášen(a) pirátskou identitou, ale ne jako člen(ka) strany.<br/>
                    Na zasedání CF se musíte se registrovat jako host.<br/>

                    Pokud se snad chcete přihlásit pod jinou identitou, je
                      nutné se nejprve{" "}
                      <button className="underline" onClick={keycloak.logout}>
                        odhlásit
                      </button>
                      .
                    </p>
                    <Button
                      className="btn--blue-300 max-w-full sm:max-w-xs lg:max-w-full mt-4"
                      bodyClassName="whitespace-no-wrap"
                      color="black"
                      routerTo="/registrace"
                      onClick={() => setAudience("guest")}>
                      Registrovat jako host
                    </Button>
                  </div>

                )}
              </div>
            </div>
          )}

          {audience && audience!=="notmember" && questions && products && (
            <>
              <h2 className="head-heavy-sm">
                2. Nyní prosím vyplňte registrační formulář
              </h2>
              <RegistrationForm
                key={registrationFormRenderKey}
                fixedName={
                  (audience === "member" || audience === "regp") ? keycloak.tokenParsed.name : null
                }
                fixedEmail={
                  (audience === "member" || audience === "regp") ? keycloak.tokenParsed.email : null
                }
                showOrganization={audience !== "member" && audience !== "journalist" && audience !== "regp"}
                showMedia={audience == "journalist"}
                showQuestions={audience !== "journalist"}
                showLunch={audience == "satan"}
                showParties={audience !== "journalist"}
                showPrintouts={audience == "satan"}
                showDonation={audience !== "journalist"}
                questions={questions}
                products={products}
                onSubmit={setFormData}
                className={classNames({ hidden: !!formData })}
              />
              {formData && (
                <div className="alert alert--grey-125 leading-normal items-center space-x-4">
                  <span className="ico--checkbox-checked text-3xl" />
                  <p>
                    Hotovo, vyplněno! Něco nesedí? Tak to{" "}
                    <button
                      className="underline"
                      onClick={() => setFormData(undefined)}
                    >
                      můžete upravit
                    </button>
                    .
                  </p>
                </div>
              )}
            </>
          )}

          {formData && (
            <>
              <h2 className="head-heavy-sm">3. Dokončení</h2>
              <div className="space-y-4">
                <p className="leading-normal">
                  Nyní už jen zbývá vše potvrdit. Pokud vše souhlasí, klikněte
                  na tlačítko níže.
                </p>
                {submitError && (
                  <p className="alert alert--error leading-normal">
                    Je nám líto, ale při odesílání došlo k nějakému problému,
                    zkuste to prosím ještě jednou.
                  </p>
                )}
                {submitDuplicity && (
                  <p className="alert alert--dark leading-normal">
                    <span>
                      Zdá se, že{" "}
                      <strong>registraci pro váš email již máme</strong>. Pokud
                      si přejete registraci nějak upravit, kontaktujte prosím
                      někoho{" "}
                      <Link to="/organizatori" className="underline">
                        z organizátorů
                      </Link>
                      .
                    </span>
                  </p>
                )}
                <Button
                  className="text-lg max-w-full col-span-1"
                  bodyClassName="whitespace-no-wrap"
                  color="black"
                  type="submit"
                  loading={submitting}
                  onClick={submit}
                >
                  Dokončit registraci
                </Button>
              </div>
            </>
          )}
        </div>
      )}

      {submitted && response.total === "0.00" && (
        <div className="alert alert--blue-300 leading-normal space-x-4">
          <span className="ico--checkbox-checked text-3xl" />
          <div>
            <h2 className="head-heavy-sm mb-2">
              Vaše registrace byla úspěšná, těšíme se naviděnou!
            </h2>
            <p>
              <strong>Pozor! Systém nezasílá potvrzovací e-mail se vstupenkou.</strong><br/>
              E-mail s odkazem ke stažení vstupenek, které budete
              potřebovat při vstupu do objektu jednacího sálu obdržíte později.
              Mějte prosím strpení, k odeslání odkazu <strong>může dojít až za několik dní</strong>,
              některé registrace totiž musíme osobně zkontrolovat.
            </p>
          </div>
        </div>
      )}
      {submitted && response.total !== "0.00" && (
        <div className="alert alert--blue-300 leading-normal space-x-4">
          <span className="ico--checkbox-checked text-3xl" />
          <div>
            <h2 className="head-heavy-sm mb-2">
              Vaše registrace byla úspěšná, těšíme se naviděnou!
            </h2>
            <p>
              <strong>Pozor! Systém nezasílá potvrzovací e-mail se vstupenkou.</strong><br/>
              E-mail s odkazem ke stažení vstupenek, které budete
              potřebovat při vstupu do objektu jednacího sálu obdržíte později.
              Mějte prosím strpení, k odeslání odkazu <strong>může dojít až za několik dní</strong>,
              některé registrace totiž musíme osobně zkontrolovat.
            </p>
            <p>
              <br/>
              V registračním formuláři jste zvolil/a některé placené
              položky (např. stravování nebo tištěné materiály), případně chcete finančně přispět na pořádání akce.<br/>
              Následující pokyny použijte k zaplacení služeb, případně si je uložte k realizaci platby později.
              <br/><br/>
            </p>
            <div className="grid md:grid-cols-2 gap-4 md:gap-8 content-block">
              <div>
                <p>
                  <strong>Pokyny k platbě:</strong><br/>
                  Číslo účtu: {response.payment_request.account}<br/>
                  Částka: {response.payment_request.amount} Kč<br/>
                  Variabilní symbol: {response.payment_request.vs}<br/>
                  Konstantní symbol: {response.payment_request.ks}<br/>
                  Specifický symbol: {response.payment_request.ss}<br/><br/>
                  Případně použijte QR kód k platbě z Vašeho mobilního telefonu.
                </p>
              </div>
              <div>
                <p>
                  <img src={appurl.baseUrl+response.payment_request.qr} alt="platba - qr kód" />
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </article>
  );
};

export default Registration;
