import { Link } from "react-router-dom";
import Button from "components/Button";
import classNames from "classnames";

import { Card, CardBody, CardBodyText } from "./cards";

const VenueCard = ({
  children,
  title,
  startinfo,
  endinfo,
  title_short,
  text,
  street,
  city,
  gps,
  href,
  infolink,
  programlink,
  className,
  link,
  showtop,
  ...props
}) => {
  const cls = classNames(className, "bg-grey-125 rounded card--hoveractive elevation-1", {});
  let toppart = "";
  let hhref = "webové stránky";
  if (showtop) {
    hhref = href;
    toppart = (
      <CardBodyText>
        <h3 className="head-alt-md">
          {text}
        </h3>
        <p className="text-sm">
          Začátek: {startinfo}
          <br />
          Konec: {endinfo}
        </p>
        <div>

          <Button
            className="text-xs btn--to-blue-300 mr-2 mb-2"
            bodyClassName="whitespace-no-wrap p-2 pl-4 pr-4"
            color="black"
            icon="ico--info"
            routerTo={infolink}
          >
            Další informace
          </Button>

          <Button
            className="text-xs btn--to-blue-300 mr-2 mb-2"
            bodyClassName="whitespace-no-wrap  p-2 pl-4 pr-4"
            color="black"
            icon="ico--calendar"
            routerTo={programlink}
          >
            Program
          </Button>

        </div>
      </CardBodyText>
    );
  }
  const body = (
    <Card className={cls} {...props}>
      <CardBody className="lg:p-6">
        {toppart}
        <h4 className="head-alt-sm">{title_short}</h4>
        <p className="text-sm">
          {street}<br />
          {city}<br />
          <i className="ico--map"></i>&nbsp;{gps}<br />
          <i className="ico--link"></i>&nbsp;<a href={href} target="_blank" rel="noreferrer noopener">{hhref}</a>
        </p>
        {children}
      </CardBody>
    </Card>
  );

  if (link) {
    return (
      <Link to={link} className="hover:no-underline">
        {body}
      </Link>
    );
  }

  return body;
};

export default VenueCard;
